import Player from '@vimeo/player'

export type Props = {
  autoplay?: boolean
  src: string
  title?: string
} & React.ComponentPropsWithoutRef<'iframe'>

export default function Vimeo({ autoplay, src, title, ...otherProps }: Props) {
  const url = new URL(src)

  if (autoplay) {
    url.searchParams.append('autoplay', 'true')
  }

  /**
   * Remove 3rd-party cookies and tracking.
   * https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Player-parameters-overview
   */

  url.searchParams.append('dnt', 'true')

  return (
    <iframe
      allow='autoplay; fullscreen; picture-in-picture'
      onLoad={setPlaybackRate}
      src={`${url}`}
      title={title || src}
      {...otherProps}
    />
  )

  function setPlaybackRate(event: React.SyntheticEvent<HTMLIFrameElement, Event>) {
    const player = new Player(event.target as HTMLIFrameElement)
    const playbackRate = parseFloat(localStorage.getItem('playbackRate') || '1')

    player.setPlaybackRate(playbackRate)

    player.on('playbackratechange', (data) => {
      localStorage.setItem('playbackRate', `${data.playbackRate}`)
    })
  }
}

Vimeo.scheme = {
  render: 'Vimeo',
  attributes: {
    src: { type: String, required: true },
    title: { type: String }
  }
}
